import {createApp} from "vue";
import App from './App'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus'
import * as ElementPlusIconVue from '@element-plus/icons-vue'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/dist/index.css'

import './css/input.css'


// console.log(process.env.VUE_APP_API) //当前环境接口路径
// console.log(process.env.NODE_ENV) //当前环境
const app = createApp(App)
// 去掉vue警告
app.config.warnHandler = (msg, instance, trace) => {}
app.use(store)
.use(router)
// element 汉化
app.use(ElementPlus, {
    locale: zhCn,
})
for (const [key,component] of Object.entries(ElementPlusIconVue)) {
    app.component(key,component)
}

app.mount('#app')