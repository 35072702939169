<template>
  <div class="app">
    <el-container v-if="isLogin" class="container">
      <el-header class="header">
        <el-row>
          <el-col :span="2">
            <div class="left">
              <!-- <img @click="onLogo()" src="../src/assets/logo.png"> -->
              <img @click="onLogo()" src="../src/assets/zao.png">
            </div>
          </el-col>
          <el-col :span="18">
            <div class="cell">
              <div class="cell-item" v-for="(item, index) in navs">
                <WaymonBtn v-if="index == tabIndex" :active_img="item.active_img" :title="item.title" :index="tabIndex"
                  @click="onTabRouter(index)" />
                <WaymonBtn v-else :img="item.img" :title="item.title" :index="tabIndex" @click="onTabRouter(index)" />
              </div>
            </div>
          </el-col>
          <el-col :span="2">
            <div class="setting">
              <el-dropdown>
                <span class="el-dropdown-link">
                  <img src="../src/assets/set.png">
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item :icon="Edit" @click="onPassword">修改密码</el-dropdown-item>
                    <el-dropdown-item :icon="SwitchButton" @click="onOut">退出</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </el-col>
        </el-row>
      </el-header>
      <el-container>
        <el-aside width="150px" class="aside">
          <!-- home  -->
          <el-menu default-active="0" v-if="tabIndex == 0 && slideIndex == -1">
            <el-menu-item v-for="item in slides" :router="true" @click="onHomeSlideRouter(item.index)">
              <div class="menu">{{ item.title }}</div>
            </el-menu-item>
          </el-menu>
          <!-- 其他 -->
          <el-menu active-text-color="#4478FE" :default-active="state.slideIndex" v-if="tabIndex > 0"
            @select="handleSelect">
            <el-menu-item v-for="(item, index) in slides" :router="true" :index="index">{{ item.title }}</el-menu-item>
          </el-menu>

        </el-aside>
        <!-- main -->
        <el-container class="main">
          <el-main v-if="isLogin">
            <router-view v-if="isRouterActive" v-slot="{ Component }">
              <keep-alive>
                <component :is="Component" :key="$route.name" v-if="$route.meta.keepAlive" />
              </keep-alive>
              <component :is="Component" :key="$route.name" v-if="!$route.meta.keepAlive" />
            </router-view>
          </el-main>
          <el-footer class="footer">© 2022 , Inc. All rights reserved. by Waymon</el-footer>
        </el-container>
      </el-container>
    </el-container>
    <el-container v-else class="container">
      <router-view />
    </el-container>
    <!--  修改密码  -->
    <el-dialog v-model="isEdit" title="修改密码" width="30%" top="25vh" center>
      <el-form :model="state.elForm" :rules="rules" ref="editForm">
        <el-form-item label="旧密码" :label-width="width">
          <el-input v-model="state.elForm.password" />
        </el-form-item>
        <el-form-item label="旧密码" :label-width="width">
          <el-input v-model="state.elForm.new_password" />
        </el-form-item>
        <el-form-item label="重制密码" :label-width="width">
          <el-input v-model="state.elForm.confirm_password" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isEdit = false">取消</el-button>
          <el-button type="primary" :disabled="disabled" @click="onEdit()"> 确认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { nextTick, onMounted, provide, reactive, ref, toRefs } from "vue";
import WaymonBtn from "@/components/WaymonBtn";
import router from "@/router";
import { navs } from '@/utils/nav'
import { getStorage, removeStorage, setStorage } from "@/utils/util";
import { ElMessage, ElNotification } from "element-plus";
import { Edit, SwitchButton } from '@element-plus/icons-vue'
import { Check } from "@/api/check";
import { AdminPassword } from "@/api/admin";
const isRouterActive = ref(true)
const editForm = ref(null)
const disabled = ref(false)
const state = reactive({
  isLogin: false,
  slideIndex: -1,
  tabIndex: 0,
  slides: [],
  admin: {},
  isEdit: false,
  width: "100px",
  elForm: {
    password: '',
    new_password: '',
    confirm_password: ''
  }
})
const rules = reactive({
  password: [{
    required: true,
    message: '密码不能为空',
    trigger: 'blur'
  }],
  new_password: [{
    required: true,
    message: '新密码不能为空',
    trigger: 'blur'
  }],
  confirm_password: [{
    required: true,
    message: '确认密码不能为空',
    trigger: 'blur'
  }]
})
onMounted(() => {
  Check({

  }).then((res) => {
    if (res.status == 1010) {
      state.isLogin = false
      removeStorage('token')
      removeStorage('admin')
      router.push({ path: "/Login" })
      return
    }
  })
  state.slides = navs[state.tabIndex].subs
  const token = getStorage('token')
  if (!token) {
    state.isLogin = false
    router.push({ path: "/Login" })
  } else {
    state.isLogin = true
    const admin = getStorage('admin')
    state.admin = admin
    //
    ElNotification({
      title: '欢迎' + admin.name + '登录!',
      dangerouslyUseHTMLString: true,
      message: '登录时间 : ' + admin.last_time + '</br>' + '登录ip : ' + admin.last_ip + '</br>' + '登录次数 : ' + admin.logins,
      position: 'bottom-right',
    })
  }
  //
  let index = getStorage('tabIndex')
  if (index != null && index != undefined) {
    state.tabIndex = index
    // if (index == 0) {
    //   state.slideIndex = -1
    // } else {
    //   state.slideIndex = 0
    // }
    state.slides = navs[index].subs
  }
  let slideIndex = getStorage('slideIndex')

  if (slideIndex != null && slideIndex != undefined) {
    state.slideIndex = slideIndex
  }
})
const onLogo = () => {
  state.tabIndex = 0
  state.slideIndex = -1
  setStorage('tabIndex', state.tabIndex)
  setStorage('slideIndex', state.slideIndex)
  state.slides = navs[state.tabIndex].subs
  router.push({ path: "/Home" })
}
const onTabRouter = (index) => {
  state.tabIndex = index
  if (index == 0) {
    state.slideIndex = -1
  } else {
    state.slideIndex = 0
  }
  setStorage('tabIndex', index)
  setStorage('slideIndex', state.slideIndex)
  state.slides = navs[index].subs
  router.push({ path: navs[index].url })
}
const onHomeSlideRouter = (index) => {
  console.log(111)
  state.tabIndex = index
  state.slideIndex = 0
  state.slides = navs[index].subs
  setStorage('tabIndex', state.tabIndex)
  setStorage('slideIndex', state.slideIndex)
  router.push({ path: navs[index].url })
}
const handleSelect = (index) => {
  state.slideIndex = index
  setStorage('slideIndex', state.slideIndex)
  router.push({ path: navs[state.tabIndex].subs[index].url })
}
const reload = () => {
  isRouterActive.value = false
  nextTick(() => {
    isRouterActive.value = true
  })
}
const onPassword = () => {
  state.isEdit = true
}
const onEdit = () => {
  if (state.elForm.new_password != state.elForm.confirm_password) {
    ElMessage.error("新密码和确认密码不一致")
    return
  }
  disabled.value = true
  editForm.value.validate((valid) => {
    if (valid) {
      AdminPassword({
        password: state.elForm.password,
        new_password: state.elForm.new_password,
        confirm_password: state.elForm.confirm_password,
      }).then((res) => {
        disabled.value = false
        if (res.status == 0) {
          ElMessage.success('修改成功')
          state.isEdit = false
        } else {
          ElMessage.error(res.msg)
        }
      })
    } else {
      ElMessage.error("错误")
    }
  })
}
const onOut = () => {
  removeStorage('token')
  removeStorage('admin')
  state.isLogin = false
  router.push({ path: "/Login" })
}
provide('reload', {
  reload
})
//使用toRefs解构
const {
  isLogin,
  slideIndex,
  tabIndex,
  slides,
  isEdit,
  width,
} = toRefs(state)

</script>
<style scoped>
body,
html {
  padding: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  color: #fff;
}

.app {
  min-height: 100vh;
  background: #fff;
}

.container {
  width: 100%;
  height: 100vh;
}

.aside {}

.main {
  background: #F4F4F4;
}

.header {
  padding: 0;
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #F4F4F4;
}

.footer {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
}

.left {
  width: 100%;
  height: 80px;
  padding-left: 40px;
  /* text-align: center; */
}

.left img {
  margin-top: 5px;
  width: 72px;
  height: 72px;
  /* width: 100px;
  height: 35px; */
}

.cell {
  width: 100%;
  height: 80px;
  display: flex;
}

.setting {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.setting img {
  width: 30px;
  height: 30px;
}

/*  menu */
.menu {
  width: 150px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #333333;
}


:deep(input::-webkit-inner-spin-button),:deep(input::-webkit-outer-spin-button) {
  -webkit-appearance: none !important;
}

:deep(input[type='number']) {
  -moz-appearance: textfield !important;
}
</style>
