export const navs = [
    {
        img: require("../assets/home/item/item1.png"),
        active_img:require("../assets/home/item/item1_s.png"),
        title: "首页",
        url:"/Home",
        subs:[
            {
                title:'商品管理',
                index:1,
                url:'/Product',
            },
            {
                title:'订单管理',
                index:2,
                url:'/Order',
            },
            {
                title:'用户管理',
                index:3,
                url:'/Member',
            },
            {
                title:'商家管理',
                index:4,
                url:'/Shop',
            },
            {
                title:'推广管理',
                index:5,
                url:'/Promotion',
            },
            {
                title:'区域管理',
                index:6,
                url:'/Point',
            },
            {
                title:'财务管理',
                index:7,
                url:'/Account',
            },
            {
                title:'数据中心',
                index:8,
                url:'/Data',
            },
            {
                title:'营销设置',
                index:9,
                url:'/Market',
            },
            {
                title:'反馈设置',
                index:10,
                url:'/Report',
            },
            {
                title:'系统设置',
                index:11,
                url:'/Setting',
            },
        ]
    },
    {
        img: require("../assets/home/item/item2.png"),
        active_img:require("../assets/home/item/item2_s.png"),
        title: "商品",
        url:"/Product",
        subs:[
            {
                title:'商品管理',
                url:"/Product",
            },
            {
                title:'分类管理',
                url:"/Category"
            },
            // {
            //     title:'二级分类',
            //     url:"/Brand"
            // },
            {
                title:'商品标签',
                url:"/Mend"
            }
        ]
    },
    {
        img: require("../assets/home/item/item3.png"),
        active_img: require("../assets/home/item/item3_s.png"),
        title: "订单",
        url:"/Order",
        subs: [
            {
                title:'订单管理',
                url:'/Order',
            },
        ]
    },
    {
        img: require("../assets/home/item/item4.png"),
        active_img: require("../assets/home/item/item4_s.png"),
        title: "用户",
        url:"/Member",
        subs: [
            {
                title:"用户管理",
                url:"/Member",
            }
        ]
    },
    {
        img: require("../assets/home/item/item5.png"),
        active_img: require("../assets/home/item/item5_s.png"),
        title: "商家",
        url:"/Shop",
        subs: [
            {
                title:"商家管理",
                url:"/Shop"
            },
            {
                title:"商家财务",
                url:"/ShopAccount"
            }
        ]
    },
    {
        img: require("../assets/home/item/item12.png"),
        active_img: require("../assets/home/item/item12_s.png"),
        title: "推广",
        url:"/Promotion",
        subs: [
            {
                title:"推广管理",
                url:"/Promotion"
            }
        ]
    },
    {
        img: require("../assets/home/item/item6.png"),
        active_img: require("../assets/home/item/item6_s.png"),
        title: "区域",
        url:"/Point",
        subs: [
            {
                title: "区域管理",
                url:"/Point"
            },
            {
                title: "区域负责人",
                url:"/Manager"
            }
        ]
    },
    {
        img: require("../assets/home/item/item7.png"),
        active_img: require("../assets/home/item/item7_s.png"),
        title: "财务",
        url:"/Withdraw",
        subs: [
            {
              title: "财务管理",
              url: "/Withdraw"
            }
        ]
    },
    {
        img: require("../assets/home/item/item8.png"),
        active_img: require("../assets/home/item/item8_s.png"),
        title: "数据",
        url:"/Data",
        subs: [
            {
                title: "交易数据",
                url: "/Data",
            },
            {
                title: "商品分析",
                url: "/DataProduct",
            },
            {
                title: "用户数据",
                url: "/DataMember",
            },
            {
                title: "商家数据",
                url: "/DataShop",
            },
            {
                title: "营销数据",
                url: "/DataMarket",
            },
            {
                title: "财务数据",
                url: "/DataAccount",
            },
        ]
    },
    {
        img: require("../assets/home/item/item9.png"),
        active_img: require("../assets/home/item/item9.png"),
        title: "营销",
        url:"/Market",
        subs: [
            {
                title:"营销中心",
                url:"/Market",
            }
        ]
    },
    {
        img: require("../assets/home/item/item10.png"),
        active_img: require("../assets/home/item/item10_s.png"),
        title: "意见反馈",
        url:"/Report",
        subs: [
            {
                title:"意见反馈",
                url:"/Report"
            }
        ]
    },
    {
        img: require("../assets/home/item/item11.png"),
        active_img: require("../assets/home/item/item11_s.png"),
        title: "设置",
        url:"/ShopBanner",
        subs: [
            {
                title:"商家端设置",
                url:"/ShopBanner",
            },
            {
                title:"用户端设置",
                url:"/Wechat",
            },
            {
                title:"平台设置",
                url:"/Platform",
            },
        ]
    },
]
