import { createStore } from 'vuex'
import {getStorage, setStorage} from "@/utils/util";

export default createStore({
  state: {
    admin:{}
  },
  getters: {
    admin(state) {
      return state.admin.id ? state.admin : getStorage('admin')
    }
  },
  mutations: {
    setAdmin(state,admin){
      state.admin = admin || {}
      setStorage('admin',admin)
    }
  },
  actions: {
    setAdmin(ctx ,admin) {
      ctx.commit('setAdmin',admin)
    }
  },
  modules: {
  }
})
