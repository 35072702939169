import  http from "@/utils/http";

export function AdminInfo(params) {
    return  http({
        url: "admin/info",
        method: "get",
        params: params
    })
}

export function AdminPassword(params) {
    return  http({
        url: "admin/password",
        method: "post",
        data: params
    })
}