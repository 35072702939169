// export const apiUrl = 'http://127.0.0.1:8081/api/v1/'
// export const apiUrl = 'https://back.zaodiantao.com/api/v1/'
export const apiUrl = process.env.VUE_APP_API
export function getStorage(key) {
    const value = window.sessionStorage.getItem(key)
    try {
        return JSON.parse(window.sessionStorage.getItem(key))
    } catch (e) {
        return value
    }
}

export function setStorage(key, value) {
    window.sessionStorage.setItem(key, JSON.stringify(value))
}

export function removeStorage(key) {
    window.sessionStorage.removeItem(key)
}

export function getCityName(regionData, value) {
    let options = JSON.parse(JSON.stringify(regionData));
    let cityName = [];
    for (let key in options) {
        if (options[key].value == value[0]) {
            cityName.push(options[key].label);
            for (let item in options[key].children) {
                if (options[key].children[item].value == value[1]) {
                    cityName.push(options[key].children[item].label);
                    for (let cell in options[key].children[item].children) {
                        if (options[key].children[item].children[cell].value == value[2]) {
                            cityName.push(options[key].children[item].children[cell].label);
                        }
                    }
                }
            }
        }
    }
    return cityName;
}
export function getCityCode(regionData, value) {
    let options = JSON.parse(JSON.stringify(regionData));
    let cityName = [];
    for (let key in options) {
        if (options[key].label == value[0]) {
            cityName.push(options[key].value);
            for (let item in options[key].children) {
                if (options[key].children[item].label == value[1]) {
                    cityName.push(options[key].children[item].value);
                    for (let cell in options[key].children[item].children) {
                        if (options[key].children[item].children[cell].label == value[2]) {
                            cityName.push(options[key].children[item].children[cell].value);
                        }
                    }
                }
            }
        }
    }
    return cityName;
}
// 省份数据
// export const options = [
//     {
//         value: "110000",
//         label: '北京市',
//     },
//     {
//         value: "120000",
//         label: '天津市',
//     },
//     {
//         value: "130000",
//         label: '河北省',
//     },
//     {
//         value: "140000",
//         label: '山西省',
//     },
//     {
//         value: '150000',
//         label: '内蒙古自治区'
//     },
//     {
//         value: '210000', 
//         label: '辽宁省',
//     },
//     {
//         value: '220000', 
//         label: '吉林省',
//     },
//     {
//         value: '230000', 
//         label: '黑龙江省',
//     },
//     {
//         value: '310000', label: '上海市',
//     },
//     {
//         value: '320000', label: '江苏省',
//     },
//     {
//         value: '330000', label: '浙江省',
//     },
//     {
//         value: '340000', label: '安徽省',
//     },
//     {
//         value: '350000', label: '福建省',
//     },
//     {
//         value: '360000', label: '江西省',
//     },
//     {
//         value: '370000', label: '山东省',
//     },
//     {
//         value: '410000', label: '河南省',
//     },
//     {
//         value: '420000', label: '湖北省',
//     },
//     {
//         value: '430000', label: '湖南省',
//     },
//     {
//         value: '440000', label: '广东省',
//     },
//     {
//         value: '450000', label: '广西壮族自治区'
//     },
//     {
//         value: '460000', label: '海南省',
//     },
//     {
//         value: '500000', label: '重庆市',
//     },
//     {
//         value: '510000', label: '四川省',
//     },
//     {
//         value: '520000', label: '贵州省',
//     },
//     {
//         value: '530000', label: '云南省',
//     },
//     {
//         value: '540000', label: '西藏自治区',
//     },
//     {
//         value: '610000', label: '陕西省',
//     },
//     {
//         value: '620000', label: '甘肃省',
//     },
//     {
//         value: '630000', label: '青海省',
//     },
//     {
//         value: '640000', label: '宁夏回族自治区',
//     },
//     {
//         value: '650000', label: '新疆维吾尔自治区',
//     },
//     {
//         value: '710000', label: '台湾省',
//     },
//     {
//         value: '810000', label: '香港特别行政区',
//     },
//     {
//         value: '820000', label: '澳门特别行政区',
//     }
// ]