import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: 'Home'
  },
  {
    path: '/Home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      title: "首页"
    }
  },
  {
    path: '/Product',
    name: 'Product',
    component: () => import('../views/Product.vue'),
    meta: {
      title: "所有商品",
      keepAlive: true
    }
  },
  {
    path: '/ProductSell',
    name: 'ProductSell',
    component: () => import('../views/ProductSell.vue'),
    meta: {
      title: "出售中的商品",
      keepAlive: true
    }
  },
  {
    path: '/ProductAdd',
    name: 'ProductAdd',
    component: () => import('../views/ProductAdd.vue'),
    meta: {
      title: "添加商品"
    }
  },
  {
    path: '/ProductSecond',
    name: 'ProductSecond',
    component: () => import('../views/ProductSecond.vue'),
    meta: {
      title: "添加商品"
    }
  },
  {
    path: '/ProductDetailAdd',
    name: 'ProductDetailAdd',
    component: () => import('../views/ProductDetailAdd.vue'),
    meta: {
      title: "商品详情"
    }
  },
  {
    path: '/Comment',
    name: 'Comment',
    component: () => import('../views/Comment.vue'),
    meta: {
      title: "评论列表"
    }
  },
  {
    path: '/CommentAdd',
    name: 'CommentAdd',
    component: () => import('../views/CommentAdd.vue'),
    meta: {
      title: "评论添加"
    }
  },
  {
    path: '/ProductShen',
    name: 'ProductShen',
    component: () => import('../views/ProductShen.vue'),
    meta: {
      title: "审核中的商品",
      keepAlive: true
    }
  },
  {
    path: '/ProductEdit',
    name: 'ProductEdit',
    component: () => import('../views/ProductEdit.vue'),
    meta: {
      title: "商品编辑"
    }
  },
  {
    path: '/ProductInfo',
    name: 'ProductInfo',
    component: () => import('../views/ProductInfo.vue'),
    meta: {
      title: "商品详情"
    }
  },
  {
    path: '/ProductDetailInfo',
    name: 'ProductDetailInfo',
    component: () => import('../views/ProductDetailInfo.vue'),
    meta: {
      title: "商品详情"
    }
  },
  {
    path: '/Category',
    name: 'Category',
    component: () => import('../views/Category.vue'),
    meta: {
      title: "早餐分类"
    }
  },
  {
    path: '/CategoryLunch',
    name: 'CategoryLunch',
    component: () => import('../views/CategoryLunch.vue'),
    meta: {
      title: "午餐分类"
    }
  },
  {
    path: '/Brand',
    name: 'Brand',
    component: () => import('../views/Brand.vue'),
    meta: {
      title: "二级分类"
    }
  },
  {
    path: '/BrandAdd',
    name: 'BrandAdd',
    component: () => import('../views/BrandAdd.vue'),
    meta: {
      title: "二级分类添加"
    }
  },
  {
    path: '/BrandAddLunch',
    name: 'BrandAddLunch',
    component: () => import('../views/BrandAddLunch.vue'),
    meta: {
      title: "二级分类添加"
    }
  },
  {
    path: '/BrandEdit',
    name: 'BrandEdit',
    component: () => import('../views/BrandEdit.vue'),
    meta: {
      title: "二级分类编辑"
    }
  },
  {
    path: '/BrandEditLunch',
    name: 'BrandEditLunch',
    component: () => import('../views/BrandEditLunch.vue'),
    meta: {
      title: "二级分类编辑"
    }
  },
  {
    path: '/Order',
    name: 'Order',
    component: () => import('../views/Order.vue'),
    meta: {
      title: "用户订单",
      keepAlive: true
    }
  },
  {
    path: '/OrderDetail',
    name: 'OrderDetail',
    component: () => import('../views/OrderDetail.vue'),
    meta: {
      title: "用户订单"
    }
  },
  {
    path: '/OrderProduct',
    name: 'OrderProduct',
    component: () => import('../views/OrderProduct.vue'),
    meta: {
      title: "商家订单",
      keepAlive: true
    }
  },
  {
    path: '/OrderProductDetail',
    name: 'OrderProductDetail',
    component: () => import('../views/OrderProductDetail.vue'),
    meta: {
      title: "商家订单"
    }
  },
  {
    path: '/Refund',
    name: 'Refund',
    component: () => import('../views/Refund.vue'),
    meta: {
      title: "退款订单"
    }
  },
  {
    path: '/RefundDetail',
    name: 'RefundDetail',
    component: () => import('../views/RefundDetail.vue'),
    meta: {
      title: "退款订单"
    }
  },
  {
    path: '/Member',
    name: 'Member',
    component: () => import('../views/Member.vue'),
    meta: {
      title: "用户"
    }
  },
  {
    path: '/MemberEdit',
    name: 'MemberEdit',
    component: () => import('../views/MemberEdit.vue'),
    meta: {
      title: "用户编辑"
    }
  },
  {
    path: '/Address',
    name: 'Address',
    component: () => import('../views/Address.vue'),
    meta: {
      title: "取餐信息"
    }
  },
  {
    path: '/Shop',
    name: 'Shop',
    component: () => import('../views/Shop.vue'),
    meta: {
      title: "商家"
    }
  },
  {
    path: '/ShopAdd',
    name: 'ShopAdd',
    component: () => import('../views/ShopAdd.vue'),
    meta: {
      title: "添加商家"
    }
  },
  {
    path: '/ShopAccount',
    name: 'ShopAccount',
    component: () => import('../views/ShopAccount.vue'),
    meta: {
      title: "账户明细"
    }
  },
  {
    path: '/ShopBankCard',
    name: 'ShopBankCard',
    component: () => import('../views/ShopBankCard.vue'),
    meta: {
      title: "商家银行卡"
    }
  },
  {
    path: '/ShopAccountDetail',
    name: 'ShopAccountDetail',
    component: () => import('../views/ShopAccountDetail.vue'),
    meta: {
      title: "财务账户详情"
    }
  },
  {
    path: '/ShopDetail',
    name: 'ShopDetail',
    component: () => import('../views/ShopDetail.vue'),
    meta: {
      title: "店铺详情"
    }
  },
  {
    path: '/ShopShenDetail',
    name: 'ShopShenDetail',
    component: () => import('../views/ShopShenDetail.vue'),
    meta: {
      title: "店铺审核详情"
    }
  },
  {
    path: '/ShopEdit',
    name: 'ShopEdit',
    component: () => import('../views/ShopEdit.vue'),
    meta: {
      title: "编辑店铺"
    }
  },
  {
    path: '/ShopShen',
    name: 'ShopShen',
    component: () => import('../views/ShopShen.vue'),
    meta: {
      title: "待审核商家"
    }
  },
  {
    path: '/ShopPoint',
    name: 'ShopPoint',
    component: () => import('../views/ShopPoint.vue'),
    meta: {
      title: "商家站点审核"
    }
  },
  {
    path: '/ShopPrint',
    name: 'ShopPrint',
    component: () => import('../views/ShopPrint.vue'),
    meta: {
      title: "打印列表"
    }
  },
  {
    path: '/ShopPrintAdd',
    name: 'ShopPrintAdd',
    component: () => import('../views/ShopPrintAdd.vue'),
    meta: {
      title: "打印列表添加"
    }
  },
  {
    path: '/Promotion',
    name: 'Promotion',
    component: () => import('../views/Promotion.vue'),
    meta: {
      title: "推广"
    }
  },
  {
    path: '/PromotionData',
    name: 'PromotionData',
    component: () => import('../views/PromotionData.vue'),
    meta: {
      title: "推广数据"
    }
  },
  {
    path: '/PromotionDataCount',
    name: 'PromotionDataCount',
    component: () => import('../views/PromotionDataCount.vue'),
    meta: {
      title: "统计明细"
    }
  },
  {
    path: '/PromotionDataLaXin',
    name: 'PromotionDataLaXin',
    component: () => import('../views/PromotionDataLaXin.vue'),
    meta: {
      title: "拉新明细"
    }
  },
  {
    path: '/PromotionDataShouDan',
    name: 'PromotionDataShouDan',
    component: () => import('../views/PromotionDataShouDan.vue'),
    meta: {
      title: "首单明细"
    }
  },
  {
    path: '/PromotionDataGift',
    name: 'PromotionDataGift',
    component: () => import('../views/PromotionDataGift.vue'),
    meta: {
      title: "礼包销售"
    }
  },
  {
    path: '/PromotionAdd',
    name: 'PromotionAdd',
    component: () => import('../views/PromotionAdd.vue'),
    meta: {
      title: "添加推广员"
    }
  },
  {
    path: '/PromotionEdit',
    name: 'PromotionEdit',
    component: () => import('../views/PromotionEdit.vue'),
    meta: {
      title: "编辑推广员"
    }
  },
  {
    path: '/PromotionInfo',
    name: 'PromotionInfo',
    component: () => import('../views/PromotionInfo.vue'),
    meta: {
      title: "推广员详情"
    }
  },
  {
    path: '/Agent',
    name: 'Agent',
    component: () => import('../views/Agent.vue'),
    meta: {
      title: "代理商列表"
    }
  },
  {
    path: '/AgentWithdrawal',
    name: 'AgentWithdrawal',
    component: () => import('../views/AgentWithdrawal.vue'),
    meta: {
      title: "代理商提现"
    }
  },
  {
    path: '/AgentAdd',
    name: 'AgentAdd',
    component: () => import('../views/AgentAdd.vue'),
    meta: {
      title: "代理商添加"
    }
  },
  {
    path: '/AgentEdit',
    name: 'AgentEdit',
    component: () => import('../views/AgentEdit.vue'),
    meta: {
      title: "代理商编辑"
    }
  },
  {
    path: '/AgentFinance',
    name: 'AgentFinance',
    component: () => import('../views/AgentFinance.vue'),
    meta: {
      title: "代理商财务"
    }
  },
  {
    path: '/AgentDivideAccounts',
    name: 'AgentDivideAccounts',
    component: () => import('../views/AgentDivideAccounts.vue'),
    meta: {
      title: "代理商分账账户"
    }
  },
  {
    path: '/Withdrawal',
    name: 'Withdrawal',
    component: () => import('../views/Withdrawal.vue'),
    meta: {
      title: "提现设置"
    }
  },
  {
    path: '/Point',
    name: 'Point',
    component: () => import('../views/Point.vue'),
    meta: {
      title: "区域"
    }
  },
  {
    path: '/PointAdd',
    name: 'PointAdd',
    component: () => import('../views/PointAdd.vue'),
    meta: {
      title: "添加站点"
    }
  },
  {
    path: '/PointEdit',
    name: 'PointEdit',
    component: () => import('../views/PointEdit.vue'),
    meta: {
      title: "编辑站点"
    }
  },
  {
    path: '/Manager',
    name: 'Manager',
    component: () => import('../views/Manager.vue'),
    meta: {
      title: "区域负责人"
    }
  },
  {
    path: '/ManagerAdd',
    name: 'ManagerAdd',
    component: () => import('../views/ManagerAdd.vue'),
    meta: {
      title: "添加区域负责人"
    }
  },
  {
    path: '/ManagerEdit',
    name: 'ManagerEdit',
    component: () => import('../views/ManagerEdit.vue'),
    meta: {
      title: "编辑区域负责人"
    }
  },
  {
    path: '/Withdraw',
    name: 'Withdraw',
    component: () => import('../views/Withdraw.vue'),
    meta: {
      title: "邀请有礼提现"
    }
  },
  {
    path: '/RechargeWithdrawal',
    name: 'RechargeWithdrawal',
    component: () => import('../views/RechargeWithdrawal.vue'),
    meta: {
      title: "充值拉新提现"
    }
  },
  {
    path: '/ShopWithdraw',
    name: 'ShopWithdraw',
    component: () => import('../views/ShopWithdraw.vue'),
    meta: {
      title: "商家提现申请"
    }
  },
  {
    path: '/Data',
    name: 'Data',
    component: () => import('../views/Data.vue'),
    meta: {
      title: "交易概况"
    }
  },
  {
    path: '/DataAnalyse',
    name: 'DataAnalyse',
    component: () => import('../views/DataAnalyse.vue'),
    meta: {
      title: "交易分析"
    }
  },
  {
    path: '/DataOrder',
    name: 'DataOrder',
    component: () => import('../views/DataOrder.vue'),
    meta: {
      title: "下单数据"
    }
  },
  {
    path: '/DataPay',
    name: 'DataPay',
    component: () => import('../views/DataPay.vue'),
    meta: {
      title: "支付数据"
    }
  },
  {
    path: '/DataProduct',
    name: 'DataProduct',
    component: () => import('../views/DataProduct.vue'),
    meta: {
      title: "商品概况"
    }
  },
  {
    path: '/DataRefund',
    name: 'DataRefund',
    component: () => import('../views/DataRefund.vue'),
    meta: {
      title: "退单统计"
    }
  },
  {
    path: '/DataMember',
    name: 'DataMember',
    component: () => import('../views/DataMember.vue'),
    meta: {
      title: "用户数据"
    }
  },
  {
    path: '/DataMarket',
    name: 'DataMarket',
    component: () => import('../views/DataMarket.vue'),
    meta: {
      title: "优惠券统计"
    }
  },
  {
    path: '/DataAssist',
    name: 'DataAssist',
    component: () => import('../views/DataAssist.vue'),
    meta: {
      title: "优惠券统计"
    }
  },
  {
    path: '/DataShop',
    name: 'DataShop',
    component: () => import('../views/DataShop.vue'),
    meta: {
      title: "商家收益排行"
    }
  },
  {
    path: '/DataAccount',
    name: 'DataAccount',
    component: () => import('../views/DataAccount.vue'),
    meta: {
      title: "财务数据"
    }
  },
  {
    path: '/DataAccountLine',
    name: 'DataAccountLine',
    component: () => import('../views/DataAccountLine.vue'),
    meta: {
      title: "财务数据"
    }
  },
  {
    path: '/DataPlatform',
    name: 'DataPlatform',
    component: () => import('../views/DataPlatform.vue'),
    meta: {
      title: "财务数据"
    }
  },
  {
    path: '/DataAccount',
    name: 'DataAccount',
    component: () => import('../views/DataAccount.vue'),
    meta: {
      title: "财务数据"
    }
  },
  {
    path: '/Market',
    name: 'Market',
    component: () => import('../views/Market.vue'),
    meta: {
      title: "营销首页"
    }
  },
  {
    path: '/ShopBanner',
    name: 'ShopBanner',
    component: () => import('../views/ShopBanner.vue'),
    meta: {
      title: "banner列表"
    }
  },
  {
    path: '/ShopBannerAdd',
    name: 'ShopBannerAdd',
    component: () => import('../views/ShopBannerAdd.vue'),
    meta: {
      title: "添加轮播图"
    }
  },
  {
    path: '/ShopBannerEdit',
    name: 'ShopBannerEdit',
    component: () => import('../views/ShopBannerEdit.vue'),
    meta: {
      title: "编辑轮播图"
    }
  },
  {
    path: '/ShopNotice',
    name: 'ShopNotice',
    component: () => import('../views/ShopNotice.vue'),
    meta: {
      title: "公告管理"
    }
  },
  {
    path: '/ShopNoticeAdd',
    name: 'ShopNoticeAdd',
    component: () => import('../views/ShopNoticeAdd.vue'),
    meta: {
      title: "公告添加 "
    }
  },
  {
    path: '/ShopNoticeEdit',
    name: 'ShopNoticeEdit',
    component: () => import('../views/ShopNoticeEdit.vue'),
    meta: {
      title: "编辑公告消息"
    }
  },
  {
    path: '/Wechat',
    name: 'Wechat',
    component: () => import('../views/Wechat.vue'),
    meta: {
      title: "微信群设置"
    }
  },
  {
    path: '/Banner',
    name: 'Banner',
    component: () => import('../views/Banner.vue'),
    meta: {
      title: "首页广告",
      keepAlive: true
    }
  },
  {
    path: '/AppVersion',
    name: 'AppVersion',
    component: () => import('../views/AppVersion.vue'),
    meta: {
      title: "App设置",
    }
  },
  {
    path: '/HomeNotice',
    name: 'HomeNotice',
    component: () => import('../views/HomeNotice.vue'),
    meta: {
      title: "首页公告",
    }
  },
  {
    path: '/BannerAdd',
    name: 'BannerAdd',
    component: () => import('../views/BannerAdd.vue'),
    meta: {
      title: "微信群设置"
    }
  },
  {
    path: '/BannerEdit',
    name: 'BannerEdit',
    component: () => import('../views/BannerEdit.vue'),
    meta: {
      title: "微信群设置"
    }
  },
  {
    path: '/WechatAdd',
    name: 'WechatAdd',
    component: () => import('../views/WechatAdd.vue'),
    meta: {
      title: "微信群添加"
    }
  },
  {
    path: '/WechatEdit',
    name: 'WechatEdit',
    component: () => import('../views/WechatEdit.vue'),
    meta: {
      title: "编辑微信群"
    }
  },
  {
    path: '/RefundCategory',
    name: 'RefundCategory',
    component: () => import('../views/RefundCategory.vue'),
    meta: {
      title: "退款原因设置"
    }
  },
  {
    path: '/Company',
    name: 'Company',
    component: () => import('../views/Company.vue'),
    meta: {
      title: "商家入住"
    }
  },

  {
    path: '/CompanyTag',
    name: 'CompanyTag',
    component: () => import('../views/CompanyTag.vue'),
    meta: {
      title: "商家入住标签"
    }
  },
  {
    path: '/Partner',
    name: 'Partner',
    component: () => import('../views/Partner.vue'),
    meta: {
      title: "商务合作"
    }
  },
  {
    path: '/Report',
    name: 'Report',
    component: () => import('../views/Report.vue'),
    meta: {
      title: "用户反馈"
    }
  },
  {
    path: '/ShopReport',
    name: 'ShopReport',
    component: () => import('../views/ShopReport.vue'),
    meta: {
      title: "商家反馈"
    }
  },
  {
    path: '/ReportCategory',
    name: 'ReportCategory',
    component: () => import('../views/ReportCategory.vue'),
    meta: {
      title: "反馈类型"
    }
  },
  {
    path: '/Invite',
    name: 'Invite',
    component: () => import('../views/Invite.vue'),
    meta: {
      title: "优惠券"
    }
  },
  {
    path: '/InviteEdit',
    name: 'InviteEdit',
    component: () => import('../views/InviteEdit.vue'),
    meta: {
      title: "优惠券"
    }
  },
  {
    path: '/InviteStatic',
    name: 'InviteStatic',
    component: () => import('../views/InviteStatic.vue'),
    meta: {
      title: "优惠券"
    }
  },
  {
    path: '/InviteData',
    name: 'InviteData',
    component: () => import('../views/InviteData.vue'),
    meta: {
      title: "优惠券"
    }
  },
  {
    path: '/Assist',
    name: 'Assist',
    component: () => import('../views/Assist.vue'),
    meta: {
      title: "助力免单"
    }
  },
  {
    path: '/AssistEdit',
    name: 'GroupEdit',
    component: () => import('../views/AssistEdit.vue'),
    meta: {
      title: "邀请有礼"
    }
  },
  {
    path: '/AssistData',
    name: 'AssistData',
    component: () => import('../views/AssistData.vue'),
    meta: {
      title: "邀请有礼"
    }
  },
  {
    path: '/Coupon',
    name: 'Coupon',
    component: () => import('../views/Coupon.vue'),
    meta: {
      title: "优惠券"
    }
  },
  {
    path: '/Seckill',
    name: 'Seckill',
    component: () => import('../views/Seckill.vue'),
    meta: {
      title: "限时秒杀",
      keepAlive: true
    }
  },
  {
    path: '/SeckillAdd',
    name: 'SeckillAdd',
    component: () => import('../views/SeckillAdd.vue'),
    meta: {
      title: "限时秒杀添加"
    }
  },
  // {
  //   path: '/Optimize',
  //   name: 'Optimize',
  //   component: () => import('../views/CouponAddOptimize.vue'),
  //   meta: {
  //     title: "优化测试"
  //   }
  // },
  {
    path: '/SeckillEdit',
    name: 'SeckillEdit',
    component: () => import('../views/SeckillEdit.vue'),
    meta: {
      title: "限时秒杀编辑"
    }
  },
  {
    path: '/CouponRecord',
    name: 'CouponRecord',
    component: () => import('../views/CouponRecord.vue'),
    meta: {
      title: "优惠券"
    }
  },
  {
    path: '/CouponAdd',
    name: 'CouponAdd',
    component: () => import('../views/CouponAdd.vue'),
    meta: {
      title: "添加优惠券"
    }
  },
  {
    path: '/CouponEdit',
    name: 'CouponEdit',
    component: () => import('../views/CouponEdit.vue'),
    meta: {
      title: "编辑优惠券"
    }
  },
  {
    path: '/CouponPackage',
    name: 'CouponPackage',
    component: () => import('../views/CouponPackage.vue'),
    meta: {
      title: "优惠券礼包"
    }
  },
  {
    path: '/AddCouponPackage',
    name: 'AddCouponPackage',
    component: () => import('../views/AddCouponPackage.vue'),
    meta: {
      title: "添加优惠券券包"
    }
  },
  {
    path: '/EditCouponPackage',
    name: 'EditCouponPackage',
    component: () => import('../views/EditCouponPackage.vue'),
    meta: {
      title: "编辑优惠券券包"
    }
  },
  {
    path: '/CouponPackageRecord',
    name: 'CouponPackageRecord',
    component: () => import('../views/CouponPackageRecord.vue'),
    meta: {
      title: "优惠券券包领取记录"
    }
  },
  {
    path: '/Platform',
    name: 'Platform',
    component: () => import('../views/Platform.vue'),
    meta: {
      title: "添加优惠券"
    }
  },
  {
    path: '/Mend',
    name: 'Mend',
    component: () => import('../views/Mend.vue'),
    meta: {
      title: "添加优惠券"
    }
  },
  {
    path: '/MendAdd',
    name: 'MendAdd',
    component: () => import('../views/MendAdd.vue'),
    meta: {
      title: "添加优惠券"
    }
  },
  {
    path: '/MendEdit',
    name: 'MendEdit',
    component: () => import('../views/MendEdit.vue'),
    meta: {
      title: "添加优惠券"
    }
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: "登录"
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
