import axios from "axios";
import { ElMessage } from "element-plus";
import {apiUrl, getStorage} from "@/utils/util";
//用来拦截用的
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
//创建一个单例
// console.log(process.env.VUE_APP_API)
const http= axios.create({
    baseURL: process.env.VUE_APP_API,
    headers: {"Content-Type":"application/json;charset=utf-8"},
})

//拦截器  -请求拦截
http.interceptors.request.use(config=>{
    //部分接口需要token
    let token = getStorage('token')
    if(token){
        config.headers.token=token;
        config.headers = {
          'Authorization':token
        }
    }
    return config;
},err=>{
    return Promise.reject(err)
})

//拦截器  -响应拦截
http.interceptors.response.use(res=>{
    if (res.headers["content-disposition"]) {
        var fileName = decodeURIComponent(res.headers["content-disposition"].split("=")[1])
        var fileName = fileName.substring(1,fileName.length-1);
        // console.log(fileName);
        const blob = new Blob([res.data], {type: 'application/vnd.ms-excel;charset=utf-8'})
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = fileName
        document.body.appendChild(link)
        link.click();
        ElMessage.success({
          message: "导出成功"
        })
    }
    if(res.status === 200){
        if (typeof res.data !== 'object') {
            return Promise.reject(res)
        }
        return res.data
    }else{
        return Promise.reject(res.data)
    }
},err=>{
    return Promise.reject(err)
});

//整体导出
export default http;
